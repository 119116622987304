if ($('#prod').length){
    const firebaseConfig = {
        apiKey: "AIzaSyCS1Pm-oagReL8wpv0tQAtgKWpHaw37iJU",
        authDomain: "system-start-programming.firebaseapp.com",
        projectId: "system-start-programming",
        storageBucket: "system-start-programming.appspot.com",
        messagingSenderId: "533007188026",
        appId: "1:533007188026:web:96db45ab462518e0e3f503",
        measurementId: "G-CC4K3X9J78"
    };
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();

}else if ($('#stag').length){
    const firebaseConfig = {
        apiKey: "AIzaSyDlHsbiEfmCQxNUCxYYk6e_QZ9WvNvyGOk",
        authDomain: "stapro-system-stg.firebaseapp.com",
        projectId: "stapro-system-stg",
        storageBucket: "stapro-system-stg.appspot.com",
        messagingSenderId: "1079005715355",
        appId: "1:1079005715355:web:a5be0809ad238b0da9df3e"
    };
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
}
export default firebase



